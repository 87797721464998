import * as axios from 'axios'
import cookie from '@/utils/cookies'
import config from '@/config'

const createUpload = (url, params = {}, cb) => {
  const cancelToken = axios.CancelToken
  const source = cancelToken.source()
  const uploadConfig = {
    onUploadProgress: (progressEvent) => {
      const complete = (progressEvent.loaded / progressEvent.total * 100 | 0)
      if (cb) {
        cb(complete, source)
      }
    },
    headers: {
      'Content-Type': 'multipart/form-data',
      token: cookie.getInClient(config.tokenName)
    },
    baseURL: config.baseUrl,
    cancelToken: source.token,
    timeout: 1000 * 60 * 90
  }
  if (process.client) {
    uploadConfig.baseURL = '/gateway'
    uploadConfig.headers.token = cookie.getInClient(config.tokenName)
    uploadConfig.headers.sid = cookie.getInClient('shopId')
  }
  return new Promise((resolve, reject) => {
    axios.post(url + '?token=' + cookie.getInClient(config.tokenName), params, uploadConfig).then((res) => {
      resolve(res.data)
    }).catch((error) => {
      if (axios.isCancel(error)) { // 主要是这里
        // console.log('取消成功')
      }
      reject(error)
    })
  })
}

/**
 * 上传图片 - 登录
 * @param data
 * @returns {*}
 */
export const uploadAuthPic = (param = {}, cb) => {
  return createUpload('/base/auth/upload/pic', param, cb)
}
